AgrimarketControllers.controller('saveComMarketInfoController', ['$scope', '$rootScope', '$routeParams', '$sce', '$filter', '$http', '$location', 'enumFactory', 'communicationMarketInfoService', 'configurationFactory', 
    function ($scope, $rootScope, $routeParams, $sce, $filter, $http, $location, enumFactory, communicationMarketInfoService, configurationFactory) {

    $scope.forceLoad = false;
    $scope.comMarketInfo = {};  
    $scope.idComMarketInfoSelected = $routeParams.id ? $routeParams.id : 0;
    $scope.weekDays = [];
    $scope.monthDays = [];
    $scope.enumTypeSends = enumFactory.TypeSends;

    var setWeekAndMonthDays = function() {
        for (var i = 1; i <= 31; i++) {
            $scope.monthDays.push({ id: i, value: i });
        }
        $scope.weekDays.push({ id: 1, value: 'Lundi' });
        $scope.weekDays.push({ id: 2, value: 'Mardi' });
        $scope.weekDays.push({ id: 3, value: 'Mercredi' });
        $scope.weekDays.push({ id: 4, value: 'Jeudi' });
        $scope.weekDays.push({ id: 5, value: 'Vendredi' });
    };
    var init = function () { 
        communicationMarketInfoService.GetById($scope.idComMarketInfoSelected, function (data, status) {
            if (status !== null) {
                if (status == 400)
                    $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
            } else {              
                $scope.comMarketInfo = data;
                setWeekAndMonthDays();
                $scope.forceLoad = true;
            }
        });
    };

    var checkComMarketInfoToSave = function() {
        $scope.errorMessage = "";
        if (!$scope.comMarketInfo.Label) {
            $scope.errorMessage = "Veuillez renseigner le nom du PDF.<br />";
        }
        if ($scope.comMarketInfo.LstGroupContactSelecteds.length === 0) {
            $scope.errorMessage += "Veuillez sélectionner au moins un groupe de contact.<br />";
        }
        if (!$scope.comMarketInfo.IdTypeSend) {
            $scope.errorMessage += "Veuillez renseigner le type d'envoi.<br />";
        } else {
            if (!$scope.comMarketInfo.HourSend && $scope.comMarketInfo.IdTypeSend !== enumFactory.TypeSends.Immediat) {
                $scope.errorMessage += "Veuillez renseigner l'heure.<br />";
            }
            if ($scope.comMarketInfo.IdTypeSend === enumFactory.TypeSends.Hebdomadaire && !$scope.comMarketInfo.DayOfWeekSend) {
                $scope.errorMessage += "Veuillez renseigner le jour de la semaine.<br />";
            }
            if ($scope.comMarketInfo.IdTypeSend === enumFactory.TypeSends.Mensuel && !$scope.comMarketInfo.DayOfMonthSend) {
                $scope.errorMessage += "Veuillez renseigner le jour.<br />";
            }
        }
    };

    $scope.Save = function () {
        checkComMarketInfoToSave();
        if ($scope.errorMessage) {
            return;
        }

        $scope.disabledButtonActions = true;

        communicationMarketInfoService.Save($scope.comMarketInfo, function (data, status) {
            if (status !== null) {
                if (status == 400)
                    $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
            } else {
                if ($scope.comMarketInfo.IdTypeSend === enumFactory.TypeSends.Immediat)
                    $location.path("/admin/editorial/comMarketInfo/msgConf");
                else {
                    $location.path("/admin/editorial/comMarketInfo");
                }
            }
        });
    };

    $scope.AddTag = function() {
        CKEDITOR.instances.txtComMarketInfoContent.insertHtml($scope.tagSelected);
    };

    $scope.toogleAllElementsLeft = function () {
        $scope.allSelectedLeft = !$scope.allSelectedLeft;
        $.each($scope.comMarketInfo.LstGroupContacts, function (index, item) {
            item.checked = $scope.allSelectedLeft;
        });
    };

    $scope.toogleAllElementsRight = function () {
        $scope.allSelectedRight = !$scope.allSelectedRight;
        $.each($scope.comMarketInfo.LstGroupContactSelecteds, function (index, item) {
            item.checked = $scope.allSelectedRight;
        });
    };

    $scope.AddToSelectedGroups = function () {
        $scope.allSelectedLeft = false;
        $scope.allSelectedRight = false;

        $.each($scope.comMarketInfo.LstGroupContacts, function (index, item) {
            if (item.checked) {
                item.checked = false;
                $scope.comMarketInfo.LstGroupContactSelecteds.push(item);
            }
        });

        $.each($scope.comMarketInfo.LstGroupContactSelecteds, function (index, item) {
            var indexItem = $scope.comMarketInfo.LstGroupContacts.indexOf(item);
            if (indexItem !== -1)
                $scope.comMarketInfo.LstGroupContacts.splice(indexItem, 1);
        });
    };

    $scope.DeleteInSelectedGroups = function (all) {
        $scope.allSelectedLeft = false;
        $scope.allSelectedRight = false;

            $.each($scope.comMarketInfo.LstGroupContactSelecteds, function (index, item) {
                if (item.checked) {
                    item.checked = false;
                    $scope.comMarketInfo.LstGroupContacts.push(item);
                }
            });

            $.each($scope.comMarketInfo.LstGroupContacts, function (index, item) {
                var indexItem = $scope.comMarketInfo.LstGroupContactSelecteds.indexOf(item);
                if (indexItem !== -1)
                    $scope.comMarketInfo.LstGroupContactSelecteds.splice(indexItem, 1);
            });
    };

    $scope.UploadImage = function (data) {
        CKEDITOR.instances.txtComMarketInfoContent.insertHtml("<img src='" +  $filter('uploadLinkInline')(data.PrivateLabel) + "'>");
    };

    init();
}]);
